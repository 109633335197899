.contact { 
  padding: 1rem;
}

.contact__title { 
  text-align: center;
}

.contact a { 
  color: var(--color-text);
}

.contact p {
  padding-bottom: 0.5rem;
}


