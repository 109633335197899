.bling { 
  padding: 1rem;
}

.bling__title { 
  text-align: center;
}

.bling p { 
  padding-bottom: 0.5rem;
}
