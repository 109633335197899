.home { 
  padding: 1rem;
}

.card { 
  display: block;
  /* display: grid; */
  /* grid-template-areas: */
  /*   "card-heading card-heading" */
  /*   "card-image card-blurb" */
  /*   "card-button card-button" */
  /* ; */
  /* grid-template-columns: 1fr 2fr; */

  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 2rem;

  background-color: var(--color-block-background);
  color: var(--color-block-text);
}

.card__container { 
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.card__heading {
  grid-area: card-heading;

  text-align: center;
}

.card__image { 
  grid-area: card-image;

  width: 33%;
  margin-right: 1rem;

  float: left;
}

.card__blurb { 
  grid-area: card-blurb;

  height: 100%;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  line-height: 1.6;
}

.card__button { 
  grid-area: card-button;

  display: block;  

  width: fit-content;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 1rem;

  color: var(--color-banner-text);
  background-color: var(--color-banner-background);

  text-decoration: none;
  cursor: pointer;
  clear: both;
} 

.card__button:hover { 
  color: var(--color-highlight-text);
  background-color: var(--color-highlight-background);
}



@media (min-width: 800px) { 
  .card__container { 
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;

    max-width: 1200px;
    margin: 0 auto;
  }

  .card { 
    grid-column: span 4;

    display: flex;
    flex-direction: column;
  }

  .card__blurb { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0;
  } 

  .card__image { 
    margin: 0 auto;
  }
    

}
