.footer { 
  padding: 2rem;
  padding-bottom: 0.25rem;

  background-color: var(--color-banner-background);
  color: var(--color-banner-text);
}

.footer-nav__list a { 
  display: inline-block;

  padding: 0.5rem;

  color: var(--color-banner-text);
}

.footer-nav__list a:hover { 
  background-color: var(--color-highlight-background);
  color: var(--color-highlight-text);
}

.footer a { 
  color: var(--color-banner-text);

}


.footer-nav__list { 
  display: flex;
  justify-content: space-between;

  padding: 0;
  margin: 0;

  list-style-type: none;
}

.footer__fine-print { 
  display: block;

  padding-top: 0.5rem;

  text-align: center;
}

@media (min-width: 50rem) {
}
