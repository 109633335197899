.about { 
  padding: 1rem;
}

.about__title { 
  text-align: center;
}

.about__pic { 
  float: left;

  max-width: 100%;
  padding: 0 1rem;
}

.about p { 
  padding-bottom: 0.5rem;
}

.about a { 
  color: var(--color-text);
}
