.pricing { 
  padding: 1rem;
}

.pricing__title { 
  text-align: center;
}

.pricing p { 
  padding-bottom: 0.5rem;
}
