.gallery {
}

.gallery__list { 
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  padding: 0;
  margin: 0;

  list-style-type: none;
}

.gallery__item { 
  grid-column: span 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 800px) { 
  .gallery__item { 
    grid-column: span 3;
  }
}



