.custom { 
  padding: 1rem;
}

.custom__title { 
  text-align: center;
}

.custom__list { 
  padding-top: 2rem;
}

.custom li { 
  padding-bottom: 0.5rem;
}

