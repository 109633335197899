:root { 
  --color-general-background: #5ab497;
  --color-text: black;
  --color-banner-background: #3A8369;
  --color-banner-text: white;
  --color-accent-background: #008080;
  --color-accent-text: white;
  --color-block-background: #c5c3d6;
  --color-block-text: black;
  --color-highlight-background: #379bfd;
  --color-highlight-text: black;
}

.app { 
  display: grid;
  grid-template-rows: auto 1fr auto;

  min-height: 100vh;

  color: var(--color-text);
  background-color: var(--color-general-background);

}

