.alterations { 
  padding: 1rem;  

}

.alterations__title { 
  text-align: center;
}

.alterations__list { 
}

.alterations p { 
  padding-bottom: 0.5rem;
}

.alterations a { 
  color: var(--color-text);
}
