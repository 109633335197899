.header { 
  background-color: var(--color-background);
  color: var(--color-text);
}

.header__container { 
  display: grid;
  grid-template-areas:
    "title"
    "subtitle"
  ;
  grid-template-columns: auto;
}

.header__link { 
  grid-area: logo;

  display: none;
}

.header__logo { 
  vertical-align: bottom;
}

.header__title { 
  grid-area: title;

  padding: 1rem 0;
  margin: 0;
  margin-left: 65px;

  text-align: center;
}

.header__subtitle { 
  grid-area: subtitle;

  padding: 0.5rem;
  padding-top: 0;
  margin: 0;

  text-align: center;
}

@media (min-width: 50rem) { 
  .header__container { 
    display: grid;
    grid-template-areas:
    "logo title"
    "logo subtitle"
    ;
    grid-template-columns: auto 1fr;
  }

  .header__title { 
    margin: 0;
  }

  .header__link { 
    display: block;
  }

}
